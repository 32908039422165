<template>
  <div class="apply invest-intro">
    <div class="container" v-if="$store.state.account.loggedIn">
      <div class="title font-xxl">
        <div class="wrapper">
          <div>우리 기업의 투자자를</div>
          <div><b>증권형 크라우드펀딩</b>으로 모집하세요.</div>
        </div>
      </div>
      <div class="content">
        <div class="text">
          <div>증권형 크라우드펀딩은 기업이 성장하는 데 필요한 자본조달을 위해 크라우드펀딩 방식으로 투자자를 유치합니다.</div>
          <div>초기기업의 자금조달 방식으로 증권형 크라우드펀딩이 제도화되어 신청자격, 발행금액, 제한업종 등이 법으로 명시되어 아래 사항을 확인해주시고 신청해주시기 바랍니다.</div>
          <a class="btn btn-secondary font-sm" :href="$definitions.urls.investGuide" target="_blank" rel="noopener noreferrer">증권형 프로젝트 가이드 보기</a>
        </div>
        <div class="checklist" v-for="(c,idx) in checklist" :key="idx">
          <b class="subject">{{ c.subject }}</b>
          <ul class="tight">
            <li v-for="(i, idx) in c.items" :key="idx">{{ i }}</li>
          </ul>
        </div>
      </div>
      <div class="go">
        <router-link to="/apply/intro" class="btn prev btn btn-light">이전으로</router-link>
        <router-link to="/archives/5c6e8871bfc9" class="btn btn-point">프로젝트 신청하기</router-link>
      </div>
    </div>
    <NoLogin v-else-if="$store.state.account.checked"/>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import store from "@/scripts/store";
import router from "@/scripts/router";
import mixin from "@/scripts/mixin";
import NoLogin from "@/components/NoLogin";

function Component(initialize) {
  this.name = "pageApplyInvestIntro";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoLogin},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "프로젝트 신청");

      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      if (store.state.account.memberType !== "C") {
        store.commit("setSwingMessage", "증권형 프로젝트는 법인 회원만 신청이 가능합니다.");
        return router.push({path: "/apply/intro"});
      }
    });

    const checklist = [
      {
        subject: "신청 자격",
        items: [
          "업력 7년 이내 비상장 법인",
          "프로젝트성 사업 수행자(예시. 영화, 미술, 음악 등 컨텐츠 제작 프로젝트)",
          "비상장 벤처기업, 이노비즈기업, 메인비즈기업, 인증사회적기업(업력이 7년 초과해도 가능)"
        ]
      },
      {
        subject: "증권발행한도금액",
        items: [
          "크라우드펀딩으로 발행할 수 있는 증권의 연간 발행한도는 모집예정일로부터 과거 1년 동안의 공모를 통한 모집금액과 모집예정 금액을 합하여 지분증권 30억원, 채무증권 15억원 이하입니다."
        ]
      },
      {
        subject: "제한 업종",
        items: [
          "금융 및 보험업",
          "부동산업 (단, 「중소기업창업지원법 시행령」 제4조제2호에 따른 부동산업을 하는 자 중 관계 법령에 따라 허가․인가․승인․지정 등을 받아 공익을 목적으로 하는 자의 경우는 자금 모집 가능) ",
          "숙박 및 음식점업 (호텔업, 휴양콘도 운영업, 기타 관광숙박시설 운영업 및 상시근로자 20명 이상의 법인인 음식업은 가능)",
          "무도장운영업",
          "골프장 및 스키장운영업",
          "갬블링 및 베팅업",
          "기타 개인 서비스업 및 제조업이 아닌 업종으로 산업통상자원부령으로 정하는 업종"
        ]
      }
    ];

    return {component, checklist};
  }
});
</script>

<style lang="scss" scoped>
.invest-intro {
  > .container {
    > .title {
      background-image: url(/assets/img/page.apply.intro.invest.svg);
      background-size: cover;
      padding: $px25;
      width: 100%;
      color: #fff;
      height: $px208;
      text-align: center;
      margin: $px15 0;
      display: table;

      > .wrapper {
        display: table-cell;
        vertical-align: middle;
      }
    }

    .content {
      > .text {
        padding: $px40 $px330 $px40 $px40;
        background-color: #e9ecef;
        border-radius: $px4;
        position: relative;

        a {
          height: $formHeightLg;
          line-height: calc(#{$formHeightLg} - 2px);
          padding: 0 $px20;
          position: absolute;
          top: 50%;
          right: $px50;
          margin-top: $px-25;
        }
      }

      .checklist {
        margin-top: $px40;
        padding: 0 $px40;

        .subject {
          display: block;

          div {
            margin-top: $px5;
          }
        }

        ul {
          margin-top: $px15;
          padding-left: $px21;

          li {
            margin-top: $px5;
            list-style: disc;
          }
        }
      }
    }

    .go {
      padding: $px40 0;
      text-align: center;
      position: relative;

      .btn {
        padding: $px19 $px24;

        &.prev {
          margin-right: $px15;
        }
      }
    }
  }

  @media(max-width: 991px) {
    > .container {
      .content {
        .text {
          padding-right: $px40;

          a {
            position: static;
            margin-top: $px25;
          }
        }

        .checklist {
          padding-left: $px20;
          padding-right: $px20;
        }
      }
    }
  }
}
</style>